import React from "react"
import { GoogleLogin } from "react-google-login"
import { Flex } from "rebass"

import GithubIcon from "../../assets/github.svg"
import GoogleIcon from "../../assets/google.svg"
import Button from "../button"

const LoginButtons = ({
  onGoogleSucess,
  onGoogleFailure,
  onEmail,
  onGithub,
  setLoading,
}) => {
  return (
    <Flex
      sx={{
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Button
        onClick={onGithub}
        variant="cta"
        sx={{ height: "35px" }}
        bg="black"
        innerSx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <img
          style={{
            display: "inline-block",
            marginBottom: 0,
            marginRight: "8px",
            height: "18px",
          }}
          alt="GitHub"
          src={GithubIcon}
        />
        Sign in with GitHub
      </Button>
      <GoogleLogin
        clientId="526829558751-6qn37885h36h8nqpmn0fo9un4jp0dg8p.apps.googleusercontent.com"
        uxMode="popup"
        onSuccess={onGoogleSucess}
        onFailure={onGoogleFailure}
        cookiePolicy={"single_host_origin"}
        render={props => (
          <Button
            mt={3}
            bg={"#4285F4"}
            sx={{
              display: "flex",
              alignItems: "center",
              height: "35px",
            }}
            innerSx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            variant="cta"
            onClick={() => {
              setLoading(true)
              props.onClick()
            }}
          >
            <img
              style={{
                display: "inline-block",
                marginBottom: "0",
                marginRight: "8px",
              }}
              height="22px"
              src={GoogleIcon}
            />
            Sign in with Google
          </Button>
        )}
      />
      <Button
        mt={3}
        sx={{ height: "35px" }}
        variant="cta"
        onClick={onEmail}
        bg={"#7e878b"}
      >
        Sign in with Email
      </Button>
    </Flex>
  )
}

export default LoginButtons
